


















































import { useCiService } from '@/lib/Ci';
import { ProposalDoc } from '@/lib/models/ProposalModel';
import { useRouter } from '@/router/use-router';
import { computed, defineComponent } from '@vue/composition-api';
import { DateTime } from 'luxon';

export default defineComponent({
  setup() {
    const router = useRouter();
    const pList = useCiService('ProposalListService');

    // build columns data
    const data = computed(() => {
      const list = pList.state.value.snapshots;
      return list.map((snapshot) => {
        const data = snapshot.data() as ProposalDoc;
        return {
          id: snapshot.id,
          name: data.name,
          revision: data.revision,
          date: DateTime.fromMillis(data.date.toMillis()).toLocaleString(
            DateTime.DATE_SHORT,
          ),
          created_at: DateTime.fromMillis(
            data.created_at.toMillis(),
          ).toLocaleString(DateTime.DATE_SHORT),
        };
      });
    });

    const remove = (id: string) => {
      pList.remove(id);
    };

    const toDetail = (id: string) => {
      router.push({ name: 'proposal.detail', params: { proposalId: id } });
    };

    const s = {
      data,
      remove,
      toDetail,
    };
    return s;
  },
});
