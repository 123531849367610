
















import { useCiService } from '@/lib/Ci';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const pList = useCiService('ProposalListService');
    const text = ref<string>('');

    const create = () => {
      const name = text.value.trim();
      if (name.length) pList.create(name);
      text.value = '';
    };

    const s = {
      create,
      text,
    };
    return s;
  },
});
