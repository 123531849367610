










import Navbar from '@/components/navbar/Navbar.vue';
import SetupProposalCreate from '@/components/setup-proposal/SetupProposalCreate.vue';
import SetupProposalList from '@/components/setup-proposal/SetupProposalList.vue';
import { useCiService } from '@/lib/Ci';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from '@vue/composition-api';
import { Subscription } from 'rxjs';

export default defineComponent({
  components: { Navbar, SetupProposalCreate, SetupProposalList },
  setup() {
    const pList = useCiService('ProposalListService');

    let subPList: Subscription;
    onMounted(() => (subPList = pList.listen().subscribe()));
    onBeforeUnmount(() => subPList.unsubscribe());

    const s = {};
    return s;
  },
});
